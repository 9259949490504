import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { MangaChapterView } from '@/interfaces/manga-chapter-view';
import http from '@/libs/http';

const fetchMangaViews = async (
  id: string,
  page: number,
  take: number
): Promise<ApiResponse<MangaChapterView[]>> => {
  const response = (await http
    .get(`manga/${id}/view`, {
      headers: {
        'Content-Type': 'application/json',
      },
      searchParams: {
        page,
        take,
      },
    })
    .json()) as ApiResponse<MangaChapterView[]>;

  return response;
};

const useMangaViews = (
  id: string,
  page: number,
  pageSize: number,
  initialData?: any
) => {
  return useQuery({
    queryKey: ['manga-views', id, page, pageSize],
    queryFn: () => fetchMangaViews(id, page, pageSize),
    initialData,
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
};

export { fetchMangaViews, useMangaViews };
