import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { MangaChapterLike } from '@/interfaces/manga-chapter-like';
import http from '@/libs/http';

const fetchMangaLikes = async (
  id: string,
  page: number,
  take: number
): Promise<ApiResponse<MangaChapterLike[]>> => {
  const response = (await http
    .get(`manga/${id}/like`, {
      headers: {
        'Content-Type': 'application/json',
      },
      searchParams: {
        page,
        take,
      },
    })
    .json()) as ApiResponse<MangaChapterLike[]>;

  return response;
};

const useMangaLikes = (
  id: string,
  page: number,
  pageSize: number,
  initialData?: any
) => {
  return useQuery({
    queryKey: ['manga-likes', id, page, pageSize],
    queryFn: () => fetchMangaLikes(id, page, pageSize),
    initialData,
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
};

export { fetchMangaLikes, useMangaLikes };
