import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMangaViews } from '@/hooks/queries/useMangaViews';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION, MangaOriginals } from '@/libs/constants';
import { formatNumber } from '@/utils';

const GridItemTooltip = ({ data }: { data: Manga }) => {
  const [page] = useState(DEFAULT_PAGINATION.PAGE);
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);

  const { data: mangaChapterViews } = useMangaViews(data.id, page, pageSize);
  return (
    <Link href={`/title/${data.id}/${data.slug}`}>
      <div className="mx-auto flex w-40 flex-col items-center gap-y-1 md:w-56">
        <div className="group relative h-60 md:h-80">
          <LazyLoadImage
            src={data.avatar.url}
            effect="blur"
            className="h-60 w-screen scale-100 border border-default-300 md:h-80"
            aria-label="avatar-comic"
          />
          <div className="absolute right-0 top-0 flex w-full flex-row items-center justify-between gap-y-1 p-2 lg:flex-row lg:gap-x-2">
            <div className="flex h-8 w-8 items-center justify-center rounded-full border border-vivid-orange bg-white">
              <p className="text-[8px] text-vivid-orange">
                {formatNumber(mangaChapterViews?.meta.itemCount)}
              </p>
            </div>
            <div
              className={clsx(
                'flex items-center justify-center rounded-full px-2',
                {
                  'bg-lime-400':
                    data.originalsPaidNone === MangaOriginals.COMPLETED,
                  'bg-neon-blue':
                    data.originalsPaidNone === MangaOriginals.ONGOING,
                  'bg-indigo':
                    data.originalsPaidNone === MangaOriginals.ONESHOT,
                }
              )}
            >
              <p
                className={clsx(
                  'text-xs font-light capitalize lg:text-base',
                  data.originalsPaidNone === MangaOriginals.COMPLETED
                    ? 'text-black'
                    : 'text-white'
                )}
              >
                {data.originalsPaidNone.replace(/_/g, ' ')}
              </p>
            </div>
          </div>
          <div className="absolute inset-0 hidden h-full w-full rounded-md bg-gray-50/50 group-hover:block" />
        </div>
        <div className="flex w-full flex-row items-center justify-start">
          <p className="text-base font-normal text-black lg:text-lg">
            {data.name}
          </p>
        </div>
      </div>
    </Link>
  );
};
export default GridItemTooltip;
