import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMangaViews } from '@/hooks/queries/useMangaViews';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION } from '@/libs/constants';
import { formatNumber } from '@/utils';

const HorizotalScrollItem = ({ data }: { data: Manga }) => {
  const [page] = useState(DEFAULT_PAGINATION.PAGE);
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);

  const { data: mangaChapterViews } = useMangaViews(data.id, page, pageSize);

  return (
    <Link
      href={`/title/${data.id}/${data.slug}`}
      className="inline-block"
      key={data.id}
    >
      <motion.div className="relative z-20 h-40 w-28 cursor-pointer bg-transparent md:h-80 md:w-56">
        <LazyLoadImage
          src={data.avatar.url}
          className="h-40 w-screen scale-100 lg:h-80"
          effect="blur"
        />
        <div className="absolute left-2 top-2 flex h-8 w-8 items-center justify-center rounded-full border border-vivid-orange bg-white md:left-3 md:top-3 md:p-3">
          <p className="text-[8px] text-vivid-orange">
            {formatNumber(mangaChapterViews?.meta.itemCount)}
          </p>
        </div>
        <div className="absolute inset-0 flex h-full w-full flex-col items-start justify-end bg-gradient-to-t from-black/75 to-transparent">
          <div className="flex flex-col p-2 md:p-3">
            <p
              className="text-xs text-white md:text-sm"
              style={{ color: data.genres[0]?.color }}
            >
              {data.genres[0]?.name}
            </p>
            <p className="text-sm text-white md:text-xl">{data.name}</p>
            <p className="text-xs text-white md:text-sm">
              {data.user.userName}
            </p>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};
export default HorizotalScrollItem;
