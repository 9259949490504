'use client';

import dayjs from 'dayjs';
import { useSession } from 'next-auth/react';
import { useMemo, useState } from 'react';

import { useRecommendMangas } from '@/hooks/queries/useRecommendMangas';
import type { ApiResponse } from '@/interfaces/api-response';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION } from '@/libs/constants';
import { useInfiniteScroll } from '@/libs/hooks/use-infinite-scroll';

import BannerAutoScrool from './banner-auto-scroll';

const HomeBanner = () => {
  const { data: session } = useSession();

  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);

  const userAge = useMemo(() => {
    if (session?.user.dateOfBirth) {
      const birthDate = dayjs(session.user.dateOfBirth);
      const currentDate = dayjs();

      let age = currentDate.diff(birthDate, 'year');

      const hasBirthdayPassed = currentDate.isAfter(
        birthDate.month(currentDate.month()).date(currentDate.date())
      );
      if (!hasBirthdayPassed) {
        age -= 1;
      }

      return age;
    }

    return undefined;
  }, [session]);

  const { data, fetchNextPage, hasNextPage, isFetching } = useRecommendMangas(
    pageSize,
    userAge
  );

  const mangas = useMemo(() => {
    return data?.pages.reduce((acc: Manga[], page: ApiResponse<Manga[]>) => {
      return [...acc, ...page.data];
    }, []);
  }, [data]);

  const lastElementRef = useInfiniteScroll(
    fetchNextPage,
    hasNextPage,
    isFetching
  );

  return (
    <div
      className="mt-22 flex w-full flex-col overflow-hidden"
      ref={lastElementRef}
    >
      <div className="flex w-full flex-row gap-x-2 bg-black py-10">
        <div className="mx-auto flex w-full max-w-8xl flex-col items-start gap-y-3 px-4 lg:px-4">
          <p className="line-clamp-2 text-base font-normal text-vivid-orange md:text-2xl">
            RECOMMENDED SERIES
          </p>
          <BannerAutoScrool datas={mangas as Manga[]} />
        </div>
      </div>
    </div>
  );
};
export default HomeBanner;
