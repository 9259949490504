import slugify from 'slugify';

interface RandomElementProps<T extends { id: string }> {
  datas: T[];
}

export const randomElement = <T extends { id: string }>({
  datas,
}: RandomElementProps<T>): T | undefined => {
  if (datas.length === 0) {
    return undefined;
  }
  const index = Math.floor(Math.random() * datas.length);
  return datas[index];
};

export const randomElements = <T>(array: T[]): T[] => {
  const shuffledArray = [...array]; // Create a copy of the original array

  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const randomIndex = Math.floor(Math.random() * (i + 1));

    // Swap the current element with the randomly chosen element
    [shuffledArray[i], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex] as T, // Cast to type T
      shuffledArray[i] as T, // Cast to type T
    ];
  }

  return shuffledArray;
};

export const generateSlug = (text: string) => {
  return slugify(text, {
    lower: true,
    strict: true,
  }); // Remove leading/trailing dashes
};

export const formatNumber = (num?: number): string => {
  if (typeof num !== 'number' || Number.isNaN(num)) {
    return '0';
  }

  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1_000) {
    return `${(num / 1_000).toFixed(1).replace(/\.0$/, '')}K`;
  }

  return num.toString();
};
