import clsx from 'clsx';

interface GridListProps<T extends { id: string }> {
  datas: T[];
  itemGrid?: React.FC<{ data: T }>;
  title?: string;
  custom?: string;
}

const GridList = <T extends { id: string }>({
  datas,
  itemGrid: ItemGrid,
  title,
  custom = '',
}: GridListProps<T>) => {
  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-base uppercase text-black lg:text-2xl">{title}</p>
      <div
        className={clsx(
          custom
            ? `${custom}`
            : `grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-4 2xl:grid-cols-6`
        )}
      >
        {datas.slice(0, 12).map((data) => (
          <div key={data.id}>{ItemGrid && <ItemGrid data={data} />}</div>
        ))}
      </div>
    </div>
  );
};
export default GridList;
