import { useInfiniteQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { Manga } from '@/interfaces/manga';
import http from '@/libs/http';

const fetchInfiniteMangaNewlyUpdate = async (
  page: number,
  take: number,
  languageId: string,
  order?: string | undefined,
  userAge?: number | undefined
): Promise<ApiResponse<Manga[]>> => {
  const response = (await http
    .get(`manga/newly-update`, {
      searchParams: {
        page,
        take,
        languageId,
        ...(order ? { order } : {}),
        ...(userAge ? { userAge } : {}),
      },
    })
    .json()) as ApiResponse<Manga[]>;

  return response;
};

const useInfiniteMangaNewlyUpdate = (
  pageSize: number,
  languageId: string,
  order?: string,
  userAge?: number
) => {
  return useInfiniteQuery({
    queryKey: ['infiniteMangaNewlyUpdate', pageSize, languageId, userAge],
    queryFn: ({ pageParam }) =>
      fetchInfiniteMangaNewlyUpdate(
        pageParam,
        pageSize,
        languageId,
        order,
        userAge
      ),
    enabled: true,
    getNextPageParam: (lastPage) =>
      lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.meta.hasPreviousPage ? firstPage.meta.page - 1 : undefined,
    initialPageParam: 1,
  });
};

export { fetchInfiniteMangaNewlyUpdate, useInfiniteMangaNewlyUpdate };
