'use client';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';

import { useInfiniteLanguages } from '@/hooks/queries/useInfiniteLanguages';
import { useInfiniteMangaNewlyUpdate } from '@/hooks/queries/useInfiniteMangaNewlyUpdate';
import type { ApiResponse } from '@/interfaces/api-response';
import type { Language } from '@/interfaces/language';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION, LanguageStatuses } from '@/libs/constants';
import { useInfiniteScroll } from '@/libs/hooks/use-infinite-scroll';
import { randomElement } from '@/utils';

import GridList from '../grid-list';
import GridItemTooltip from '../grid-list/grid-item-tooltip';
import HorizontalScroll from '../horizontal-scroll';
import HorizotalScrollItem from '../horizontal-scroll/item';
import AllManga from './all-manga';
import HomeBanner from './home-banner';
import OneShotManga from './one-shot-manga';
import ShoutRead from './should-read';

export default function Home() {
  const { data: session } = useSession();
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const inifiniteLanguages = useInfiniteLanguages(pageSize);

  const languages = useMemo(() => {
    return inifiniteLanguages.data?.pages.reduce(
      (acc: Language[], page: ApiResponse<Language[]>) => {
        return [...acc, ...page.data];
      },
      []
    );
  }, [inifiniteLanguages]);

  const lastElementLanguagesRef = useInfiniteScroll(
    inifiniteLanguages.fetchNextPage,
    inifiniteLanguages.hasNextPage,
    inifiniteLanguages.isFetching
  );

  const currentLanguageId = useMemo(() => {
    if (session?.user.currentLanguageId) {
      return session.user.currentLanguageId;
    }

    const englishLanguage = languages?.find(
      (item: Language) => item.slug === LanguageStatuses.ENGLISH
    );
    return englishLanguage ? englishLanguage.id : '';
  }, [session, languages]);

  const userAge = useMemo(() => {
    if (session?.user.dateOfBirth) {
      const birthDate = dayjs(session.user.dateOfBirth);
      const currentDate = dayjs();

      let age = currentDate.diff(birthDate, 'year');

      const hasBirthdayPassed = currentDate.isAfter(
        birthDate.month(currentDate.month()).date(currentDate.date())
      );
      if (!hasBirthdayPassed) {
        age -= 1;
      }

      return age;
    }

    return undefined;
  }, [session]);

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteMangaNewlyUpdate(
      pageSize,
      currentLanguageId,
      DEFAULT_PAGINATION.ORDER,
      userAge
    );

  const mangasNewlyUpdate = useMemo(() => {
    return data?.pages.reduce((acc: Manga[], page: ApiResponse<Manga[]>) => {
      return [...acc, ...page.data];
    }, []);
  }, [data]);

  const lastElementRef = useInfiniteScroll(
    fetchNextPage,
    hasNextPage,
    isFetching
  );

  const randomManga = useMemo(() => {
    return randomElement({ datas: mangasNewlyUpdate || [] });
  }, [mangasNewlyUpdate]);

  const itemComicUpdated: React.FC<{ data: Manga; indexManga?: number }> = ({
    data,
    indexManga,
  }) => {
    return <HorizotalScrollItem data={data} indexManga={indexManga} />;
  };

  if (!isClient) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-y-3 pb-9">
      <HomeBanner />
      <div className="mx-auto flex w-full max-w-8xl flex-col gap-y-3">
        <div
          className="flex w-full flex-col gap-y-5"
          ref={lastElementRef || lastElementLanguagesRef}
        >
          <div className="hidden md:block">
            <HorizontalScroll
              datas={mangasNewlyUpdate as Manga[]}
              itemGrid={itemComicUpdated}
              title="NEWLY UPDATED"
            />
          </div>
          <div ref={lastElementRef} className="block md:hidden">
            <p
              className={clsx(
                'pl-3 text-base font-normal uppercase text-black md:text-2xl'
              )}
            >
              NEWLY UPDATED
            </p>
            {mangasNewlyUpdate && (
              <GridList
                datas={mangasNewlyUpdate as Manga[]}
                itemGrid={GridItemTooltip}
                custom="grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-2 xl:grid-cols-5 2xl:grid-cols-7"
              />
            )}
          </div>
        </div>
        <OneShotManga />
        <AllManga />
        {randomManga && <ShoutRead randomManga={randomManga} />}
      </div>
    </div>
  );
}
