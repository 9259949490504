'use cline';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '@nextui-org/react';
import { useCallback, useEffect, useRef } from 'react';

import type { Manga } from '@/interfaces/manga';

import ItemScroll from './item-scroll';

const BannerAutoScrool = ({ datas }: { datas: Manga[] }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handlePrev = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = useCallback(() => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 10000);

    return () => clearInterval(interval);
  }, [handleNext]);

  return (
    <div className="relative flex w-full flex-col items-center">
      <div className="z-10 flex w-full flex-nowrap items-center justify-start overflow-hidden">
        <div
          className="hide-scrollbar flex w-full gap-x-3 overflow-x-auto"
          ref={scrollContainerRef}
        >
          {datas?.map((item) => <ItemScroll data={item} key={item.id} />)}
        </div>
      </div>
      <div className="absolute inset-y-0 -left-12 z-20 hidden flex-row items-center justify-center gap-x-3 lg:flex">
        <Button
          isIconOnly
          onClick={handlePrev}
          className="rounded-full border border-white bg-transparent text-white hover:bg-black/50"
        >
          <ChevronLeftIcon width={30} height={30} />
        </Button>
      </div>
      <div className="absolute inset-y-0 -right-12 z-20 hidden flex-row items-center justify-center gap-x-3 lg:flex">
        <Button
          isIconOnly
          onClick={handleNext}
          className="rounded-full border border-white bg-transparent  text-white hover:bg-black/50"
        >
          <ChevronRightIcon width={30} height={30} />
        </Button>
      </div>
    </div>
  );
};
export default BannerAutoScrool;
