import {
  ChatBubbleOvalLeftEllipsisIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import { HeartIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMangaActions } from '@/components/providers/MangaProvider';
import { useInfiniteGenres } from '@/hooks/queries/useInfiniteGenres';
import { useMangaChapterComments } from '@/hooks/queries/useMangaChapterComents';
import { useMangaLikes } from '@/hooks/queries/useMangaLike';
import { useMangaViews } from '@/hooks/queries/useMangaViews';
import type { ApiResponse } from '@/interfaces/api-response';
import type { Genre } from '@/interfaces/genre';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION } from '@/libs/constants';
import { useInfiniteScroll } from '@/libs/hooks/use-infinite-scroll';

import ButtonReadComic from '../../button-read-comic';

const ShoutRead = ({ randomManga }: { randomManga: Manga }) => {
  const [page] = useState(DEFAULT_PAGINATION.PAGE);
  const { setSelectGenreId } = useMangaActions();

  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);
  const infiniteGenres = useInfiniteGenres(pageSize);

  const genres = useMemo(() => {
    return infiniteGenres.data?.pages.reduce(
      (acc: Genre[], page: ApiResponse<Genre[]>) => {
        return [...acc, ...page.data];
      },
      []
    );
  }, [infiniteGenres]);

  const lastElementGenreRef = useInfiniteScroll(
    infiniteGenres.fetchNextPage,
    infiniteGenres.hasNextPage,
    infiniteGenres.isFetching
  );

  const { data: mangaChapterLikes } = useMangaLikes(
    randomManga.id,
    page,
    pageSize
  );

  const { data: mangaChapterViews } = useMangaViews(
    randomManga.id,
    page,
    pageSize
  );

  const { data: mangaComments } = useMangaChapterComments(
    randomManga.id,
    page,
    pageSize
  );

  return (
    <div className="flex h-auto w-full flex-col gap-y-2 px-3 md:px-0 lg:max-h-[600px]">
      <div className="mx-auto flex w-full flex-col gap-x-6 gap-y-5 lg:flex-row">
        <div className="flex h-full w-full flex-col gap-y-4 lg:w-5/6">
          <p className="line-clamp-2 text-base font-normal text-black md:text-2xl">
            SHOULD READ
          </p>
          <div className="group relative h-[430px] w-full">
            <LazyLoadImage
              className="aspect-video h-[430px] w-screen rounded-lg border border-default-300 object-cover"
              src={randomManga.cover.url}
              effect="blur"
            />
            <ButtonReadComic data={randomManga} absolute />
          </div>
          <div className="flex w-full flex-col gap-x-4 gap-y-2 lg:flex-row">
            <div className="flex w-full flex-col xl:w-1/3">
              <div className="flex flex-row gap-x-2">
                <div className="flex flex-row items-center gap-x-1">
                  <HeartIcon width={24} height={24} className="text-red-500" />
                  <p className="text-base font-normal uppercase tracking-wider text-black md:text-lg">
                    {mangaChapterLikes?.meta.itemCount}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-x-1">
                  <EyeIcon width={24} height={24} className="text-green-500" />
                  <p className="text-base font-normal uppercase tracking-wider text-black md:text-lg">
                    {mangaChapterViews?.meta.itemCount}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-x-1">
                  <ChatBubbleOvalLeftEllipsisIcon
                    width={24}
                    height={24}
                    className="text-sky-500"
                  />
                  <p className="text-base font-normal uppercase tracking-wider text-black md:text-lg">
                    {mangaComments?.meta.itemCount}
                  </p>
                </div>
              </div>

              <p className="text-base font-normal text-black md:text-2xl">
                {randomManga.name}
              </p>
            </div>
          </div>
        </div>
        <div className="flex h-full w-full flex-col gap-y-5 lg:w-1/6">
          <p className="text-base font-normal text-black md:text-2xl">GENRES</p>
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-1">
            {genres &&
              genres.length > 0 &&
              genres.slice(0, 5).map((item: Genre) => (
                <Link
                  href="/genres"
                  onClick={() => setSelectGenreId(item.id)}
                  key={item.id}
                >
                  <div
                    className="group relative flex h-24 w-full overflow-hidden rounded-lg bg-red-200"
                    ref={lastElementGenreRef}
                  >
                    <LazyLoadImage
                      src=""
                      effect="blur"
                      className="h-24 w-screen rounded-lg object-cover group-hover:scale-105 group-hover:duration-300"
                    />
                    <div className="absolute inset-0 flex h-full w-full flex-row items-center justify-between rounded-lg bg-black/60">
                      <p className="mx-auto text-xl font-normal uppercase text-white">
                        {item.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShoutRead;
