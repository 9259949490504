import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '@nextui-org/react';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';

interface HorizontalScrollProps<T extends { id: string }> {
  datas: T[];
  itemGrid?: React.FC<{ data: T; index: number; indexManga?: number }>;
  title: string;
  hiddenTitle?: boolean;
  indexManga?: number;
}

const HorizontalScroll = <T extends { id: string }>({
  datas = [],
  itemGrid: ItemGrid,
  title,
  hiddenTitle = false,
  indexManga = 1,
}: HorizontalScrollProps<T>) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  }, []);

  const handlePrev = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScrollPosition);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, [datas, checkScrollPosition]);

  const getBgColor = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-neon-purple';
      case 2:
        return 'bg-neon-green';
      case 4:
        return 'bg-neon-orange';
      default:
        return 'bg-transparent';
    }
  };

  return (
    <div
      className={clsx(
        `flex w-full flex-col gap-y-2 py-5 ${getBgColor(indexManga)}`
      )}
    >
      <div
        className={clsx(
          'mx-auto flex w-full flex-row items-center justify-between',
          hiddenTitle && 'hidden'
        )}
      >
        <p
          className={clsx(
            'text-base font-normal uppercase md:text-2xl',
            indexManga === 0 || indexManga === 2 || indexManga === 4
              ? 'text-white'
              : 'text-black'
          )}
        >
          {title}
        </p>
        <div className="hidden flex-row items-center justify-end gap-x-3 lg:flex">
          <Button
            isDisabled={isAtStart}
            isIconOnly
            onClick={handlePrev}
            className={clsx(
              'rounded-full border bg-transparent text-black hover:bg-black/50',
              indexManga === 0 || indexManga === 2 || indexManga === 4
                ? 'border-white text-white'
                : 'border-black text-black'
            )}
          >
            <ChevronLeftIcon width={30} height={30} />
          </Button>
          <Button
            isDisabled={isAtEnd}
            isIconOnly
            onClick={handleNext}
            className={clsx(
              'rounded-full border bg-transparent text-black hover:bg-black/50',
              indexManga === 0 || indexManga === 2 || indexManga === 4
                ? 'border-white text-white'
                : 'border-black text-black'
            )}
          >
            <ChevronRightIcon width={30} height={30} />
          </Button>
        </div>
      </div>
      <div
        className={clsx(
          'hide-scrollbar z-10 flex w-full flex-nowrap items-center justify-start overflow-x-auto'
        )}
        ref={scrollContainerRef}
      >
        <div className="mx-auto flex w-full flex-row items-center justify-start gap-x-2 md:gap-x-4">
          {datas.map((data, index) => (
            <div key={data.id}>
              {ItemGrid && (
                <ItemGrid data={data} index={index} indexManga={indexManga} />
              )}
            </div>
          ))}
          {!datas && <p>No manga available</p>}
        </div>
      </div>
    </div>
  );
};
export default HorizontalScroll;
