import { PlayCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@nextui-org/react';
import clsx from 'clsx';
import Link from 'next/link';

import type { Manga } from '@/interfaces/manga';

const ButtonReadComic = ({
  data,
  absolute = false,
}: {
  data: Manga;
  absolute?: boolean;
}) => {
  return (
    <div
      className={clsx(
        `hidden h-full w-full items-center justify-center group-hover:flex`,
        absolute && 'absolute inset-0 rounded-lg group-hover:bg-black/60'
      )}
    >
      <Button
        isIconOnly
        as={Link}
        href={`title/${data?.id}/${data?.slug}`}
        className="flex h-20 w-20 items-center justify-center rounded-full border border-white bg-transparent text-white hover:bg-white hover:text-black"
      >
        <PlayCircleIcon width={60} height={60} />
      </Button>
    </div>
  );
};
export default ButtonReadComic;
