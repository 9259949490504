import { useInfiniteQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { Language } from '@/interfaces/language';
import http from '@/libs/http';

const fetchInfiniteLanguages = async (
  page: number,
  take: number
): Promise<ApiResponse<Language[]>> => {
  const response = (await http
    .get(`language`, {
      headers: {},
      searchParams: {
        page,
        take,
      },
    })
    .json()) as ApiResponse<Language[]>;

  return response;
};

const useInfiniteLanguages = (pageSize: number) => {
  return useInfiniteQuery({
    queryKey: ['infiniteLanguages', pageSize],
    queryFn: ({ pageParam }) => fetchInfiniteLanguages(pageParam, pageSize),
    enabled: true,
    getNextPageParam: (lastPage) =>
      lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.meta.hasPreviousPage ? firstPage.meta.page - 1 : undefined,
    initialPageParam: 1,
  });
};

export { fetchInfiniteLanguages, useInfiniteLanguages };
