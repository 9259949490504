import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { ChapterComment } from '@/interfaces/chapter-comment';
import http from '@/libs/http';

const fetchMangaChapterComments = async (
  id: string,
  page: number,
  take: number
): Promise<ApiResponse<ChapterComment[]>> => {
  const response = (await http
    .get(`manga/${id}/comment`, {
      headers: {
        'Content-Type': 'application/json',
      },
      searchParams: {
        page,
        take,
      },
    })
    .json()) as ApiResponse<ChapterComment[]>;

  return response;
};

const useMangaChapterComments = (
  id: string,
  page: number,
  pageSize: number,
  initialData?: any
) => {
  return useQuery({
    queryKey: ['chapter-comments', id, page, pageSize],
    queryFn: () => fetchMangaChapterComments(id, page, pageSize),
    initialData,
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
};

export { fetchMangaChapterComments, useMangaChapterComments };
